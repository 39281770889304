/// Generated by expo-google-fonts/generator
/// Do not edit by hand unless you know what you are doing
///

export { useFonts } from './useFonts';

export { default as __metadata__ } from './metadata.json';
export const Overpass_100Thin = require('./Overpass_100Thin.ttf');
export const Overpass_200ExtraLight = require('./Overpass_200ExtraLight.ttf');
export const Overpass_300Light = require('./Overpass_300Light.ttf');
export const Overpass_400Regular = require('./Overpass_400Regular.ttf');
export const Overpass_500Medium = require('./Overpass_500Medium.ttf');
export const Overpass_600SemiBold = require('./Overpass_600SemiBold.ttf');
export const Overpass_700Bold = require('./Overpass_700Bold.ttf');
export const Overpass_800ExtraBold = require('./Overpass_800ExtraBold.ttf');
export const Overpass_900Black = require('./Overpass_900Black.ttf');
export const Overpass_100Thin_Italic = require('./Overpass_100Thin_Italic.ttf');
export const Overpass_200ExtraLight_Italic = require('./Overpass_200ExtraLight_Italic.ttf');
export const Overpass_300Light_Italic = require('./Overpass_300Light_Italic.ttf');
export const Overpass_400Regular_Italic = require('./Overpass_400Regular_Italic.ttf');
export const Overpass_500Medium_Italic = require('./Overpass_500Medium_Italic.ttf');
export const Overpass_600SemiBold_Italic = require('./Overpass_600SemiBold_Italic.ttf');
export const Overpass_700Bold_Italic = require('./Overpass_700Bold_Italic.ttf');
export const Overpass_800ExtraBold_Italic = require('./Overpass_800ExtraBold_Italic.ttf');
export const Overpass_900Black_Italic = require('./Overpass_900Black_Italic.ttf');
